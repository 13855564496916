import React from "react"
import { graphql } from "gatsby"

import { SEO } from "../helpers"

import { H1, Paragraph } from "../elements"
import { Frame } from "../layout"
import { Breadcrumb, Header } from "../compositions"

import { AboutUsQuery, GraphCms_Page } from "../../../graphql-types"

type Props = {
  data: AboutUsQuery
}

function AboutUsPage({ data }: Props) {
  const page = data.graphCmsPage as GraphCms_Page

  return (
    <Frame>
      <SEO page={page} />
      <Header className="z-10" />
      <div className="bg-gradient">
        <div className="relative max-w-screen-lg mx-auto pt-16 pb-40 px-4">
          <Breadcrumb breadcrumb={page.breadcrumb} className="mb-10" />
          <H1 className="text-4xl font-bold mb-4">Biz Kimiz?</H1>
          <Paragraph className="mb-7">
            Son zamanlarda yurt dışına gitmek isteyen kişi sayısında artış
            olunca, sizlere yardımcı olabilmek için gerçek kişilerden
            topladığımız somut bilgileri içeren bir web sitesi hazırlamak
            misyonuyla yola çıkan 2 kişiyiz :)
          </Paragraph>
          <Paragraph className="mb-7">
            Bize ulaşmak isterseniz{" "}
            <a href="mailto:iletisim@cizzgi.com" className="text-purple">
              iletisim@cizzgi.com
            </a>{" "}
            adresine mail atabilirsiniz.
          </Paragraph>
          <Paragraph>Teşekkürler😇</Paragraph>
        </div>
      </div>
    </Frame>
  )
}

export default AboutUsPage

export const query = graphql`
  query AboutUs {
    graphCmsPage(url: { pathname: { eq: "/hakkimizda" } }) {
      ...PageFields
    }
  }
`
